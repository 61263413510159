import React from "react"
import Layout from "../components/layout"
import ImgLine from "../images/modal-line.png";
import {yandexMetric} from "../components/Functions/yandexMetric";

export default function PasswordForgot(props) {

    const handlerSubmit = (event) => {
        event.preventDefault()
    }

    return (
        <Layout location={props.location}>
            <>
                <div className="register-box">
                    <div className="register__head">
                        <h2>Восстановление пароля</h2>
                        <img className="register__head_img" src={ImgLine} alt="" />
                    </div>
                    <form className="register-form" onSubmit={handlerSubmit}>
                        <div className="register-form__field">
                            <input
                                required
                                name=""
                                type="email"
                                className="register-form__input"
                                id="forgot_email"
                                placeholder="Email"
                                onClick={() => yandexMetric("")}
                            />
                        </div>
                        <button
                            type="submit"
                            className="btn-overall register-form__btn auth_btn"
                            onClick={() => yandexMetric("")}
                        >
                            Восстановить пароль
                        </button>
                    </form>
                </div>
            </>
        </Layout>
    )

}

